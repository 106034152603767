.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.SearchFields {
  margin-top: 15px;
}

.DetailsButton {
  color: gray;
  background-color: white;
  border-radius: 10px;
  margin-bottom: -2px;
}

/* li {
  list-style-type: none;
} */

li {
  content: "•";
  color: rgb(150, 47, 50);
  margin-right: 10px;
  /* font-size: 30px; */
}

.grecaptcha-badge {
  visibility: hidden;
}
